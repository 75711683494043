import React from "react";

export const Footer: React.FC<{}> = () => {
    return (
        <footer className="bg-white mt-12 border-t border-gray-200">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
                <div className="flex justify-center space-x-6 md:order-2">
                    <a href="/impressum" className="text-gray-400 hover:text-gray-500">
                        Impressum
      </a>
      <a href="/datenschutz" className="text-gray-400 hover:text-gray-500">
      Datenschutz
      </a>
                </div>
                <div className="mt-8 md:mt-0 md:order-1">
                    <p className="text-center text-base text-gray-400">
                        &copy; 2023 GP Projekte GmbH.
      </p>
                </div>
            </div>
        </footer>
    )
}