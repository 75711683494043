import React from "react";
import bauarbeiter from "../../static/images/fancyglas.jpg";
import logo from "../../static/images/logo.svg";
import { Modal } from "./modal";

export const Hero: React.FC<{}> = () => {
    const [showContact, setShowContact] = React.useState(false);

    return (
        <>
            <div className="relative bg-gray-100 overflow-hidden">
                <div className="max-w-7xl mx-auto">
                    <div className="relative z-10 pb-8 bg-gray-100 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                        <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-gray-100 transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                            <polygon points="50,0 100,0 50,100 0,100" />
                        </svg>

                        <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                    <div className="flex items-center justify-between w-full md:w-auto">
                                        <a href="#">
                                            <span className="sr-only">GP Projekte GmbH</span>
                                            <img className="h-8 w-auto sm:h-10" src={logo} />
                                        </a>
                                    </div>
                                </div>
                            </nav>
                        </div>

                        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                            <div className="sm:text-center lg:text-left">
                                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                    <span className="block">Willkommen bei</span>
                                    <span className="block text-gp-blue">GP Projekte</span>
                                </h1>
                                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                    Sie sind auf der Suche nach einem zuverlässigen Partner für den Breitbandausbau?  Dann sind Sie bei uns genau richtig!
          </p>
                                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start space-y-2 lg:space-y-0 lg:space-x-4">
                                    <div className="rounded-md shadow">
                                        <button type="button" onClick={() => {
                                            setShowContact(true);
                                        }} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gp-blue hover:bg-blue-600 md:py-4 md:text-lg md:px-10">
                                            Kontakt aufnehmen
              </button>
                                    </div>
                                    <div className="rounded-md shadow">
              <a href="https://karriere.gp-projekte.de" target="_blank" rel="noopener noreferrer" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-gp-blue hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">Karriere</a>
              </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={bauarbeiter} alt="" />
                </div>
            </div>
            <Modal show={showContact} onClose={() => setShowContact(false)}>
                haha
            </Modal>
        </>
    );
}