import { Transition } from '@headlessui/react';
import React from "react";

export const Modal: React.FC<{ show: boolean; onClose: () => void; }> = ({ show, onClose, children }) => {
    if (!show) {
        return null;
    }
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                    show={!!show}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {(ref) => (
                        <div ref={ref} className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
                        </div>
                    )}
                </Transition>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <Transition
                    show={!!show}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    {(ref) => (
                        <div ref={ref} className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div>
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button type="button" onClick={onClose} className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">Close</span>
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                        Kontakt
                                    </h3>
                                    {/* <div className="mt-3">
                                        <p className="text-lg text-gray-500">
                                            Hier kommt noch ein toller Text.
                                        </p>
                                    </div> */}
                                    <div className="mt-9">
                                        {/* <div className="flex">
                                            <div className="flex-shrink-0">
                                                <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                                </svg>
                                            </div>
                                            <div className="ml-3 text-base text-left space-y-2">
                                                <div>
                                                    <p className="text-gray-500">
                                                        Büro Nordkirchen
                                                    </p>
                                                    <p className="text-gray-700">
                                                        <a href="tel:02596 9999 040">02596 9999 040</a>
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-gray-500">
                                                        Büro Köln
                                                    </p>
                                                    <p className="text-gray-700">
                                                        <a href="tel:02596 9999 041">02596 9999 041</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <svg className="h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                                </svg>
                                            </div>
                                            <div className="ml-3 text-base text-gray-700">
                                                <p>
                                                    <a href="mailto:info@gp-projekte.de">info@gp-projekte.de</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Transition>
            </div>
        </div >
    );
}