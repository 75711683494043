import React from "react";
import anga from "../../static/images/anga.jpg";

export const About: React.FC<{}> = () => {
    return (
        <div className="relative py-16 bg-white overflow-hidden max-w-7xl mx-auto">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
                <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                    <svg className="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-100" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-100" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg className="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" className="text-gray-100" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
                <div className="text-lg max-w-prose">
                    <h1>
                        <span className="mt-2 block text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Über uns</span>
                    </h1>
                </div>
                <div className="mt-6 prose-lg text-gray-500">
                    <p>
                        Egal ob für den gewerblichen oder den privaten Gebrauch – die schnelle
                        Übertragung von Daten ist in Zeiten der Digitalisierung unerlässlich.
                        Stets online sein zu können, ist für jede Kommune ein wichtiger
                        Standortfaktor, um als Wohnort, aber auch als starker Wirtschaftsstandort
                        attraktiv zu bleiben. Voraussetzung dafür ist eine starke, flächendeckende
      Infrastruktur.</p>


                    <p>Wir, die GP Projekte GmbH aus Nordkirchen, agieren als Dienstleister für
                    leistungsfähige Netze aus Glasfaser und Kupfer. Dabei übernehmen wir
                    sämtliche Aufgaben, angefangen bei der Planung der Netze über die
                    Projektierung und die Koordination der für den schlüsselfertigen Bau
                    benötigten Gewerke bis hin zur Inbetriebnahme mit dem Netzanschluss der
      Endkunden.</p>


                    <p>Die Köpfe hinter der GP Projekte GmbH – Hansjörg Pätz und Markus Gote –
                    haben mehr als 40 Jahre Erfahrung in der Kommunikationstechnik sowie in
                    der Planung und im Management von Netzausbauprojekten. Deutschlandweit
                    unterstützen wir diverse Netzbetreiber beim Aufbau ihrer
                    hochleistungsfähigen Infrastruktur, wie zum Beispiel die Deutsche
                    Glasfaser, Vodafone, Versatel oder Telecolumbus, aber auch
      Stadtnetzbetreiber von Hamburg bis München.</p>


                    <p>Durch unsere jahrelange Erfahrung im eigenwirtschaftlichen Netzausbau
                    sowie im geförderten Ausbau verfügen wir über ein fundiertes Fachwissen
      und sind bestens in der Branche vernetzt.</p>


                    <p>Und genau das ist Ihr Vorteil: Wir haben für Sie den Markt im Blick und
                    können daher sämtliche Gewerke, wie zum Beispiel Tiefbauunternehmen, immer
                    zu den besten Konditionen akquirieren. Dank unseres großen Netzwerks zu
                    diversen, qualifizierten Partnern sind Materialengpässe für uns ein
      Fremdwort.</p>


                    <p>Wir erstellen das individuell auf Ihr Projekt zugeschnittene
                    Materialkonzept, dokumentieren sämtliche Schritte gemäß Fördervorgabe und
                    stellen Ihnen alle relevanten Daten zur Verfügung – so garantieren wir
                    Ihnen während der gesamten Projektlaufzeit höchste Transparenz und
      Zuverlässigkeit.</p>
                </div>
            </div>
        </div>
    )
}