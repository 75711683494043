import React from "react";
import planung from "../../static/images/planung.png";
import projektierung from "../../static/images/projektierung.png";
import bau from "../../static/images/trommeln.jpg";
import { About } from "../components/about";
import { FeatureImgLeft, FeatureImgRight } from "../components/feature";
import { Footer } from "../components/footer";
import { Hero } from "../components/hero";

export default function Home() {
  return <div>
    <Hero/>
    <About/>
    <FeatureImgLeft
      image={planung}
    >
      <h2 className="leading-6 text-gp-blue font-semibold tracking-wide uppercase">Detaillierte</h2>
                        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Planung</h3>
                        <div className="mt-5 prose prose-indigo text-gray-500">
                        <p>Ein erfolgreiches Projekt beginnt mit einem guten Plan. Damit Ihr
          Projekt auf einer sicheren Basis steht, übernehmen wir für Sie die
          komplette Planung des Netzes.</p>


          <p>Ganz gleich, ob Sie dabei schon bestimmte Vorgaben für uns haben, oder
          ob wir die Rahmendaten für das Breitbandnetz selbst erheben – wir
          berücksichtigen sämtliche Faktoren für eine fundierte Planung. Dazu
          gehört u.a. die Erstellung eines Glasfaserkatasters, in das sämtliche
          Geo-Daten über das Ausbaugebiet und Informationen über die aktuelle
          Versorgungssituation einfließen. Darauf aufbauend können wir das
          optimale Kabelnetz samt Trassenführung für Ihr Projekt entwerfen.
          Außerdem holen wir die Baugenehmigungen bei Kommune, Land und den
          zuständigen Behörden ein und prüfen für Sie potenzielle
          Fördermöglichkeiten. Parallel dazu haben wir auch die bereits
          vorhandene Infrastruktur im Blick, sodass sich Bestandsnetze
          integrieren und Mitverlegearbeiten problemlos koordinieren lassen.</p>


          <p>Anhand unseres erstellten Konzepts können wir Ihnen präzise Kennzahlen
          zu den Ausbaukosten und zum Zeitrahmen nennen – für Ihre
          Planungssicherheit!</p>
                        </div>
    </FeatureImgLeft>

    <FeatureImgRight
      image={projektierung}
    >
      <h2 className="leading-6 text-gp-blue font-semibold tracking-wide uppercase">Umfängliche</h2>
                        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Projektierung</h3>
                        <div className="mt-5 prose prose-indigo text-gray-500">
                        <p>Eine gute Vorbereitung ist schon die halbe Miete für eine erfolgreiche
          Projektumsetzung. Mit dem Ansatz, unseren Kunden stets die
          effizienteste Lösung für ihren Netzausbau zu liefern, prüfen wir vorab
          ganz genau, welche Ausbautechnik und welches Material für das
          jeweilige Projekt passend ist.</p>


          <p>Darüber hinaus entwerfen wir einen Spleißplan, der genau festlegt, wo
          das Glasfaserkabel ankommt und wie es im Ausbaugebiet verteilt wird.
          Außerdem stellen wir sämtliche erforderlichen Tiefbauarbeiten zusammen
          wie Pflügen, Fräsen sowie die Verlegung der Kabel. Dabei kommen, je
          nach Vor-Ort-Bedingungen, ganz unterschiedliche Methoden zum Einsatz,
          wie beispielsweise Spül-Bohrungen, Pressung bis an die Hauswand oder
          das Trenchingverfahren. Dabei ist es für uns immer ein wichtiges
          Anliegen, die Beeinträchtigungen für die Anschlussnehmer durch die
          Bauarbeiten auf ein Minimum zu reduzieren.</p>


          <p>Für die jeweiligen Arbeiten kontaktieren wir für Sie die verschiedenen
          Gewerke und Dienstleister, bewerten die entsprechenden Angebote und
          wählen die für Ihr Projekt perfekten Partner aus.</p>
                        </div>
    </FeatureImgRight>

    <FeatureImgLeft
      image={bau}
    >
      <h2 className="leading-6 text-gp-blue font-semibold tracking-wide uppercase">Zuverlässiger</h2>
                        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Bau &amp; Inbetriebnahme</h3>
                        <div className="mt-5 prose prose-indigo text-gray-500">
                        <p>Auch bei der Umsetzung des Netzausbaus halten wir für Sie die Zügel in
          der Hand.</p>


          <p>Wir begleiten die Baustellen vor Ort bei den Tiefbauarbeiten sowie
          beim Verlegen und Anschließen der Glasfaserkabel. Regelmäßig stimmen
          wir uns mit den beauftragten Gewerken über den Stand der Dinge ab. So
          bleiben wir für Sie immer auf dem Laufenden und können im Fall der
          Fälle umgehend flexibel reagieren und Pläne optimieren. Fester
          Bestandteil sind dabei auch eine kontinuierliche Qualitätskontrolle
          und ein regelmäßiges Reporting.</p>


          <p>Dadurch garantieren wir Ihnen eine termingerechte Fertigstellung des
          Netzes.</p>


          <p>Unser Servicepaket endet noch längst nicht beim Bau des Netzes,
          sondern wir sorgen auch dafür, dass Ihre Endkunden schnell ans fertige
          Breitbandnetz angeschlossen werden. Dazu kontaktieren wir alle
          Anschlussnehmer vorab und besprechen mit ihnen, wie und wo ihr
          Hausanschluss ans Glasfasernetz aussieht.</p>


          <p>Auch die Abnahme mit den zuständigen Behörden und den Kommunen selbst
          gehört zu unserem Servicepaket.</p>


          <p>Darüber hinaus übergeben wir alle erforderlichen technischen Daten zum
          Netz, sodass Sie und ihre Kunden immer auf dem aktuellen Stand der
          Dinge sind.</p>
                        </div>
    </FeatureImgLeft>

    <Footer/>
    </div>
}

export function Head() {
  return <>
  <title>GP Projekte GmbH</title>
  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
<link rel="manifest" href="/site.webmanifest"/>
<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
<meta name="msapplication-TileColor" content="#da532c"/>
<meta name="theme-color" content="#ffffff"></meta>
  </>
}