import React from "react";

export const FeatureImgLeft: React.FC<{ image: any }> = ({ image, children }) => {
    return (
        <div className="relative bg-white">
            <div className="lg:absolute lg:inset-0">
                <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
                    <img className="h-56 w-full object-cover lg:absolute lg:h-full" src={image} alt="" />
                </div>
            </div>
            <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
                <div className="lg:col-start-2 lg:pl-8">
                    <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const FeatureImgRight: React.FC<{ image: any }> = ({ image, children }) => {
    return (
        <div className="relative bg-white">
            <div className="lg:absolute lg:inset-0">
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <img className="h-56 w-full object-cover lg:absolute lg:h-full" src={image} alt="" />
                </div>
            </div>
            <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
                <div className="lg:pr-8">
                    <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:ml-0">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}